import * as React from "react"
import {Link, graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({  location }) => {

  const data = useStaticQuery(graphql`
    query {
  site {
    siteMetadata {
      title
      description
    }
  }
  allFile(sort: {fields: [childrenMdx___frontmatter___date], order: DESC}) {
    nodes {
      dir: relativeDirectory
      posts: childrenMdx {
        fileAbsolutePath
        slug
        fields {
          readingTime {
            minutes
          }
        }
        excerpt
        id
        frontmatter {
          date(formatString: "MMM DD, YYYY")
          title
          subtitle
          tags
          draft
          popimg
          markup
        }
      }
    }
  }
}

`
  )
  const allFiles = data.allFile.nodes
    .filter(({posts}) => posts.length).map(({dir, posts}) => {
    return {dir, post: posts[0]}
  })

  const filteredFiles = allFiles.filter(({post}) => {
    if(post.frontmatter?.draft === true){
      return false
    }
    return post.frontmatter?.title;
  })
  const defaultTitle = data.site.siteMetadata?.title

  return (
    <Layout location={location} title={defaultTitle}>
      <Seo title={defaultTitle} />
      <ol className={'post-list'}>
        {filteredFiles.map(({post, dir}) => {
          const title = post.frontmatter.title || post.fields.slug
          const className = `post-list-item  ${post.frontmatter.subtitle ? 'post-tooltip' : ''}`
          return (
            <li key={post.slug}>
              <Link to={post.slug} itemProp="url">
              <article
                className={className }
                itemScope
                itemType="http://schema.org/Article"
                data-tooltip={post.frontmatter.subtitle}
              >
                <header>
                  <h2>
                      <span itemProp="headline" >{title}</span>
                  </h2>
                </header>
                <div className={'post-info'}>
                  <small className={'post-tag'}>#{dir}</small>
                  <small>{post.frontmatter.date}</small>
                </div>
              </article>
              </Link>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex
